import { ChevronRight } from "lucide-react";
import Musicfront from "../icons/musicfront";
import Spotify from "../icons/spotify";
import AppleMusic from "../icons/appleMusic";
import Youtube from "../icons/youtube";

const Releases = () => {
  const newestRelease = {
    title: "Hush Hush",
    type: "Single",
    image: "hush-hush.webp",
    links: {
      musicfront: "https://theblackgasolines.musicfront.app/products/66fa5ca9f65d9b001b516b2b",
      spotify: "https://open.spotify.com/album/6SkofAiR5TLADAGofMKfwU?si=yXs-HQwwTrqL5eXS4NC1PA",
      appleMusic: "https://music.apple.com/be/album/hush-hush-ep/1747757621",
      youtube: "https://www.youtube.com/watch?v=JVBoPSxt18Q",
    },
  };

  const otherReleases = [
    {
      title: "Crucify Me",
      type: "Single",
      image: "crucify-me.webp",
      links: {
        musicfront: "https://theblackgasolines.musicfront.app/products/66d732eb0c5654001bc20663",
        spotify: "https://open.spotify.com/album/5kpt313QRqCiaC97OVUZ8y?si=7RbAAUZaTe6IlohMiVzOLA",
        appleMusic: "https://music.apple.com/be/album/crucify-me-single/1747852769",
        youtube: "https://www.youtube.com/watch?v=f0QdbWO_ckw&list=OLAK5uy_kBofR2BZdh-x47xrSPglRFrFVULzG139I",
      },
    },
    {
      title: "Take Me Anywhere",
      type: "Single",
      image: "takeMeAnywhere.webp",
      links: {
        musicfront: "https://www.musicfront.app/shops/theblackgasolines/products/667e64fee13d21001b1836a3",
        spotify: "https://open.spotify.com/album/5rNlDrB2ADzgNxP539K9gH?si=qwcAKb-vS1e0Rr9RONHk1Q",
        appleMusic: "https://music.apple.com/us/album/take-me-anywhere-single/1747782071",
        youtube: "https://www.youtube.com/watch?v=jvuG-Oru2Uc",
      },
    },
    {
      title: "The Entrepreneur",
      type: "Single",
      image: "theEntrepreneur.webp",
      links: {
        musicfront: "https://www.musicfront.app/shops/theblackgasolines/products/66595e8c70a2e3001adeb9ac",
        spotify: "https://open.spotify.com/track/4B5vpDjOnznwmfoZ2077il?si=f48bb97a752946b9",
        appleMusic: "https://music.apple.com/us/album/the-entrepreneur/1747840476?i=1747840477",
        youtube: "https://www.youtube.com/watch?v=b8P9UPPn3hs",
      },
    },
    {
      title: "Peculiar",
      type: "Single",
      image: "peculiar.webp",
      links: {
        musicfront: "https://www.musicfront.app/shops/theblackgasolines/products/664cf62411685d001b608c2d",
        spotify: "https://open.spotify.com/album/4KWZhYP3F6vhRMIFJpUdCR?si=fB7V9DDcSiKwQHUlmou9BQ",
        appleMusic: "https://music.apple.com/be/album/peculiar/1656727731?i=1656727732",
        youtube: "https://www.youtube.com/watch?v=u1ZKs7pNVB8",
      },
    },
    {
      title: "It Took A Live Session",
      type: "EP",
      image: "it-took-a-live-session.webp",
      links: {
        musicfront: "https://www.musicfront.app/shops/theblackgasolines/products/658a97ec48caa1001b4d6316",
        spotify: "https://www.youtube.com/watch?v=dc_0CU9hsTs&list=OLAK5uy_klEMjbHEu_PoyVfPAwkYv716agfNyXPv4",
        appleMusic: "https://music.apple.com/be/album/it-took-a-live-session-live-at-track-single/1604759917",
        youtube: "https://www.youtube.com/watch?v=dc_0CU9hsTs",
      },
    },
    {
      title: "It Took A Lifetime",
      type: "Album",
      image: "it-took-a-lifetime.webp",
      links: {
        musicfront: "https://www.musicfront.app/shops/theblackgasolines/products/658a958748caa1001b4d6315",
        spotify: "https://open.spotify.com/album/5NNctOVOJAnpkjtDXTcJFg?si=rsNs5kmOSTCG1pJYrHw48g",
        appleMusic: "https://music.apple.com/be/album/it-took-a-lifetime/1587059333",
        youtube: "https://www.youtube.com/watch?v=XvcERvytJeE&list=OLAK5uy_mTezlcYynSJoZA_HVQlRqxhr7zSuzDWUI",
      },
    },
    {
      title: "Kangaroo Days",
      type: "Single",
      image: "kangaroo-days.webp",
      links: {
        musicfront: "https://www.musicfront.app/shops/theblackgasolines/products/658a993f48caa1001b4d6317",
        spotify: "https://open.spotify.com/album/3cXfRsSufeMGkHZapTKlPw?si=2WUzFo3KRwunrOIDw6KyPg",
        appleMusic: "https://music.apple.com/be/album/kangaroo-days-single/1515351824",
        youtube: "https://www.youtube.com/watch?v=FlNpRQNF1kU&list=OLAK5uy_nDRQjSMOS_g6FDjbYfYqeE-fRkZ246bNg",
      },
    },
    {
      title: "Long Way Suicide",
      type: "Single",
      image: "long-way-suicide.webp",
      links: {
        musicfront: "https://www.musicfront.app/shops/theblackgasolines/products/658a99bd48caa1001b4d6318",
        spotify: "https://open.spotify.com/album/16zN2DQf7sAT8Y6BgWDTRY?si=5zDwMkPMTJ62p7yIf9LG4Q",
        appleMusic: "https://music.apple.com/be/album/long-way-suicide-single/1454286955",
        youtube: "https://www.youtube.com/watch?v=dUNKAuT4mGo&list=OLAK5uy_nvkChCY74ue7Hf-mnrIQnHy9dyIm-nQ08",
      },
    },
    // {
    //   title: "Dopamine Rush",
    //   type: "EP",
    //   image: "dopamine-rush.webp",
    //   links: {
    //     musicfront: "https://www.musicfront.app/shops/theblackgasolines/products/658a9a4248caa1001b4d6319",
    //     spotify: "https://open.spotify.com/album/13Re1eK7UyjJsiNcm0b2Is?si=NP1Iqs6ASf2kE97AZ9E2LA",
    //     appleMusic: "https://music.apple.com/be/album/dopamine-rush-ep/1377450668",
    //     youtube: "https://www.youtube.com/watch?v=g9atqEnGPLs&list=OLAK5uy_mCB_CCNu4czciQJWVYR9ar2s3-BTZNyKo",
    //   },
    // },
  ];

  return (
    <div className="py-16 bg-secundary" id="music">
      <div className="container">
        <h2 className="hidden">Releases</h2>
        {newestRelease && (
          <div className="grid grid-cols-12 mb-24">
            <div className="flex flex-col justify-between col-span-12 lg:col-span-6">
              <div className="mb-4 lg:mb-0">
                <p className="mb-4 text-2xl uppercase font-title text-primary">
                  Newest Release
                </p>
                <h3 className="text-4xl uppercase lg:text-5xl xl:text-7xl font-title text-primary">
                  {newestRelease.title}
                </h3>
                <p className="mb-4 text-xl uppercase font-title text-primary">
                  {newestRelease.type}
                </p>
              </div>
              <div className="">
                <a
                  href={newestRelease.links.musicfront}
                  className="relative flex mb-6 space-x-2 group"
                >
                  <div className="w-10">
                    <Musicfront />
                  </div>
                  <p className=" text-primary font-title">Buy on Musicfront</p>
                  <div className="duration-300 group-hover:translate-x-2 transform-all">
                    <ChevronRight color="#4d3825" />
                  </div>
                </a>
                <a
                  href={newestRelease.links.spotify}
                  className="relative flex mb-6 space-x-2 group"
                >
                  <div className="w-10">
                    <Spotify />
                  </div>
                  <p className=" text-primary font-title">Listen on Spotfy</p>
                  <div className="duration-300 group-hover:translate-x-2 transform-all">
                    <ChevronRight color="#4d3825" />
                  </div>
                </a>
                <a
                  href={newestRelease.links.appleMusic}
                  className="relative flex mb-6 space-x-2 group"
                >
                  <div className="w-10">
                    <AppleMusic />
                  </div>
                  <p className=" text-primary font-title">
                    Listen on Apple Music
                  </p>
                  <div className="duration-300 group-hover:translate-x-2 transform-all">
                    <ChevronRight color="#4d3825" />
                  </div>
                </a>
                <a
                  href={newestRelease.links.youtube}
                  className="relative flex mb-6 space-x-2 group"
                >
                  <div className="w-10">
                    <Youtube />
                  </div>
                  <p className=" text-primary font-title">Watch on Youtube</p>
                  <div className="duration-300 group-hover:translate-x-2 transform-all">
                    <ChevronRight color="#4d3825" />
                  </div>
                </a>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <img
                src={`/images/releases/${newestRelease.image}`}
                alt={newestRelease.title}
              />
            </div>
          </div>
        )}
        <div className="">
          <h3 className="mb-8 text-2xl uppercase font-title text-primary">Other releases</h3>
          <div className="grid grid-cols-12 md:gap-8">
            {otherReleases.map(release => (
              <div className="col-span-12 mb-8 md:col-span-6 lg:col-span-2 lg:mb-0">
                <img className="mb-4" src={`/images/releases/${release.image}`} alt={release.title} />
                <h4 className="text-xl text-primary font-title">{release.title}</h4>
                <p className="mb-4 text-primary font-title">{release.type}</p>
                <div className="flex items-center space-x-4">
                  <a href={release.links.musicfront} className="transition-all duration-300 hover:scale-105">
                    <Musicfront />
                  </a>
                  <a href={release.links.spotify} className="transition-all duration-300 hover:scale-105">
                    <Spotify />
                  </a>
                  <a href={release.links.appleMusic} className="transition-all duration-300 hover:scale-105">
                    <AppleMusic />
                  </a>
                  <a href={release.links.youtube} className="transition-all duration-300 hover:scale-105">
                    <Youtube />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Releases;
